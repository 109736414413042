import { BaseState, IResponse, IPagination, IPaginate } from '@/types/types';
import { IUser, RootState, IShareCredit } from '../../types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import {
  changeSubAccountStatus,
  completeAccountSetUp,
  customerDetails,
  deleteSubAccount,
  listSubAccounts,
  rawListSubAccounts,
  sendPasswordResetLink,
  setUpSubAccount,
  shareCreditAcrossSubAccount,
  transferCredit,
  updateCustomerProfile,
} from '../../services/users.service';
import { map } from 'rxjs/operators';
import router from '../../router/index';

const state: BaseState<IUser> = {
  list: [],
  searchResults: [],
  details: { email: '', name: '', id: '' },
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    itemsPerPage: 30,
  },
  reset: {
    name: '',
    email: '',
    phone: '',
    disableSubAccountLogIn: false,
  },
};

const mutations: MutationTree<BaseState<IUser>> = {
  UPDATE_LIST(state, payload: ReadonlyArray<IUser>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_SEARCH_RESULTS(state, payload: ReadonlyArray<IUser>) {
    state.searchResults = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      itemsPerPage: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
};

const actions: ActionTree<BaseState<IUser>, RootState> = {
  async rawList({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IPagination<IUser>>>(
        await rawListSubAccounts()
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe((subAccounts) => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_SEARCH_RESULTS', subAccounts);
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async customerDetails({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IUser>>(
        await customerDetails(payload.accountId)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe((subAccounts) => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_DETAILS', subAccounts);
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async list({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { query, rawList } = payload;
      const response$ = of<IResponse<IPagination<IUser>>>(
        await listSubAccounts(query)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe((subAccounts) => {
        dispatch('isPageLoading', false, { root: true });
        if (!rawList) {
          const { page, limit, pages, total, docs } = subAccounts.paginateObj;
          commit('UPDATE_LIST', docs);
          commit('UPDATE_PAGINATION', {
            page,
            pages,
            total,
            itemsPerPage: limit,
          });
        } else {
          commit('UPDATE_SEARCH_RESULTS', subAccounts);
        }

        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async completeAccountSetUp({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IPagination<IUser>>>(
        await completeAccountSetUp(payload)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Account's been set up successfully`, {
          root: true,
        });
        setTimeout(() => {
          router.replace({ name: 'accounts.signin' }).catch();
          dispatch('isLoading', false, { root: true });
          dispatch('isDialogLoading', false, { root: true });
        }, 3000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async setUpSubAccount({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IPagination<IUser>>>(
        await setUpSubAccount(payload)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          payload?.disableSubAccountLogIn
            ? `Sub Account created successfully`
            : `Sub Account initiated successfully. An email will be sent to the email address to complete setup, thank you.`,
          {
            root: true,
          }
        );
        dispatch('list', { query: `?page=1&size=15` });
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async updateCustomerProfile({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id, ...rest } = payload;
      const response$ = of<IResponse<IUser>>(
        await updateCustomerProfile(id, rest)
      ).pipe(map((response) => response.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          `Sub Account details updated successfully.`,
          {
            root: true,
          }
        );
        //dispatch('list', { query: `?page=1&size=15` });
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        //dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        // dispatch('resetFormValues', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async transferCredit({ dispatch }, payload) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<any>>(await transferCredit(payload)).pipe(
        map((response) => response.data)
      );
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Credit transfer successfully completed`, {
          root: true,
        });
        dispatch('checkCreditBalance', null, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async sendPasswordResetLink({ dispatch }, payload) {
    try {
      dispatch('inProgress', true, { root: true });
      const response$ = of<IResponse<any>>(
        await sendPasswordResetLink(payload)
      ).pipe(map((response) => response.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          `Reset password link sent successfully to account's email`,
          {
            root: true,
          }
        );
        dispatch('inProgress', false, { root: true });
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async changeSubAccountStatus({ dispatch }, payload: { isActive: boolean }) {
    try {
      dispatch('isLoading', true, { root: true });

      const response$ = of<IResponse<IPagination<IUser>>>(
        await changeSubAccountStatus(payload)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          `Sub Account successfully ${
            payload.isActive ? 'activated' : 'deactivated'
          }`,
          {
            root: true,
          }
        );
        dispatch('list', { query: `?page=1&size=15` });
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async deleteSubAccount({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id } = payload;
      const response$ = of<IResponse<IPagination<IUser>>>(
        await deleteSubAccount(id)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Sub Account successfully deleted`, {
          root: true,
        });
        dispatch('list', { query: `?page=1&size=15` });
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async shareCreditAcrossSubAccount({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IShareCredit>>(
        await shareCreditAcrossSubAccount(payload)
      ).pipe(map((subAccount) => subAccount.data));
      response$.subscribe(() => {
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          `Credit units successfully shared across sub accounts`,
          {
            root: true,
          }
        );
        dispatch('list', { query: `?page=1&size=15` });
        dispatch('resetFormValues', true, { root: true });
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch('checkCreditBalance', null, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'details', state: false },
          { root: true }
        );
      });
    } catch (e: any) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IUser>, RootState> = {
  getCustomerDetails: (state) => state.details,
  getAllSubAccounts: (state) => state.list,
  getSearchResults: (state) => state.searchResults,
  getPagination: (state) => state.paginate,
  getResetFormValues: (state) => state.reset,
};

export const users: Module<BaseState<IUser>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
