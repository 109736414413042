import { Api } from './api';
import {
  IResetPassword,
  IUser,
  IVerifyAccounts,
  LoginState,
} from '@/types/types';
import { IRegisterState } from '@/types/types';

export const login = <T>(payload: LoginState): Promise<T> => {
  return Api().post(`/auth/login`, payload);
};

export const registerAsDeveloper = <T>(payload: IRegisterState): Promise<T> => {
  return Api().post(`/auth/register`, payload);
};

export const me = <T>(): Promise<T> => {
  return Api().get(`/users/profile/me`);
};

export const updateProfile = <T>(payload: IUser): Promise<T> => {
  return Api().put(`/users/profile/me`, payload);
};
export const verifyAccount = <T>(payload: IVerifyAccounts): Promise<T> => {
  return Api().post(`/auth/account/verify`, payload);
};

export const resendVerificationCode = <T>(payload: {
  email: string;
}): Promise<T> => {
  return Api().post(`/users/account/verification/code/resend`, payload);
};

export const uploadPhoto = <T>(payload: File): Promise<T> => {
  return Api().put(`/users/profile/photo/upload`, payload);
};

export const changePassword = <T>(payload: {
  oldPassword: string;
  newPassword: string;
  confirmPassword: string;
}): Promise<T> => {
  return Api().put(`/users/change/password`, payload);
};

export const forgotPassword = <T>(payload: { email: string }): Promise<T> => {
  return Api().post(`/auth/account/forgot-password`, payload);
};

export const resetPassword = <T>(payload: IResetPassword): Promise<T> => {
  return Api().post(`/auth/account/reset-password`, payload);
};
