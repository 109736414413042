import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import colors from 'vuetify/lib/util/colors';

Vue.use(Vuetify);
export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: process.env.VUE_APP_LIGHT_PRIMARY_COLOR,
        secondary: process.env.VUE_APP_LIGHT_SECONDARY_COLOR,
        accent: process.env.VUE_APP_LIGHT_ACCENT_COLOR, //1c1c1c
        error: process.env.VUE_APP_LIGHT_ERROR_COLOR,
        ripple: process.env.VUE_APP_LIGHT_RIPPLE_COLOR,
      },
      dark: {
        primary: colors.blue.lighten3,
      },
    },
  },
  icons: {
    iconfont: 'md',
  },
});
