import moment from 'moment';

/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
export const apiUrlPrefix =
  process.env.NODE_ENV === 'production' ? '/v1' : '/api/v1';

/**
 * Function to check whether a request error is a network error or axios error
 * @param err {*}
 * @returns {boolean|boolean}
 */
const isNetworkError = (err: {
  isAxiosError: unknown;
  response: unknown;
}): boolean => {
  return !!err.isAxiosError && !err.response;
};

/**
 * Function for rendering the views from the view folder
 * @param view
 * @returns {function(): *}
 */
const loadView = (view: string) => {
  return () => import(`@/views/${view}.vue`);
};

/**
 * Function for rendering the widgets from the components folder
 * @param view
 * @returns {function(): *}
 */
const loadWidget = (view: string) => {
  return () => import(`@/components/${view}.vue`);
};

export { loadView, loadWidget, isNetworkError };

/**
 * Date Format Picker for Calendar
 */
const formats: { [key: string]: string } = {
  'day|4day|week': 'YYYY-MM-DD',
  month: 'YYYY-MM',
};

export const dateFormatForCalendarTypes = (type: string) => {
  const results = [...Object.keys(formats)].find((key: string) => {
    if (key.split('|').includes(type)) {
      return key;
    }
    return null;
  });
  return results ? formats[results] : null;
};

const unitOfTime: { [key: string]: string } = {
  'day|4day': 'days',
  week: 'weeks',
  month: 'months',
};

export const momentUnitOfTimePicker = (type: string) => {
  const results = [...Object.keys(unitOfTime)].find((key: string) => {
    return key.split('|')?.includes(type) ? key : null;
  });
  return results ? unitOfTime[results] : null;
};

export const truncateContent = (value: string, length = 20) => {
  return value?.length > length ? value?.slice(0, length) + '...' : value;
};

export const dateFormat = (value: string, format?: string) => {
  return moment(value).format(format ?? 'Do MMMM, YYYY');
};

export const findDistinctNetworks = (arrayLike: string[]): string => {
  if (arrayLike && arrayLike.length > 0) {
    return [...new Set(arrayLike)].filter((network) => network).join(',');
  }
  return 'n/a';
};

export const generateEmailAddressAndPhoneNumber = (
  emailLength = 12,
  phoneLength = 9,
  domain?: string
) => {
  const types: { [key: string]: string } = {
    NUMERIC: '1234567890',
    ALPHANUMERIC:
      '1A2B3C4D5E6F7G8H9I0J1K2L3M4N5O6P7Q8R9S0T1U2V3W4X5Y6Z7a8b9c0d1e2f3g4h5i6j7k8l9m1n2o3p4q5r6s7t8u9v0w1x2y3z',
  };
  const value = (length: number, type: string) =>
    new Array(Number(length))
      .fill(0)
      .map(() =>
        types[type].charAt(Math.floor(Math.random() * types[type]?.length))
      )
      .join('');
  return {
    email:
      value(emailLength, 'ALPHANUMERIC') +
      `@${domain ? domain : 'kairosafrika.cloud'}`,
    phoneNumber: `233${value(phoneLength, 'NUMERIC')}`,
  };
};

export const generateHttpHeaders = (headers: Map<string, string>) => {
  console.log(headers);
  const httpHeaders: any = {};
  headers.forEach(([key, value]) => {
    if (value) {
      httpHeaders[key] = value;
    }
  });
  return httpHeaders;
};
