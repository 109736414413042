import { isNetworkError } from '@/utils/helpers';
import { ActionTree, GetterTree, Module, MutationTree } from 'vuex';
import { BaseState, IAPIKeys, IResponse, RootState } from '../../types/types';
import { of } from 'rxjs';
import {
  apiKey,
  apiKeyByCustomerId,
  generateAPIKey,
  revokeKeys,
} from '../../services/api-keys.service';

const namespaced = true;

const state: BaseState<IAPIKeys> = {
  list: [],
  details: {
    apiKey: '',
    apiSecret: '',
    uuid: '',
    userId: '',
  },
};

const mutations: MutationTree<BaseState<IAPIKeys>> = {
  UPDATE_DETAILS(state, payload: IAPIKeys) {
    state.details = payload;
  },
};

const actions: ActionTree<BaseState<IAPIKeys>, RootState> = {
  async details({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IAPIKeys>>(await apiKey());
      response$.subscribe((apiKeys) => {
        if (apiKeys.data) {
          const { apiKey, apiSecret, uuid } = apiKeys.data;
          commit('UPDATE_DETAILS', {
            apiKey,
            apiSecret,
            uuid,
          });
        } else {
          commit('UPDATE_DETAILS', {
            apiKey: null,
            apiSecret: null,
            uuid: '',
          });
        }
        dispatch('isPageLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async apiKeyByCustomerID({ commit, dispatch }, customerId: number) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IAPIKeys>>(
        await apiKeyByCustomerId(customerId)
      );
      response$.subscribe((apiKeys) => {
        const { apiKey, apiSecret, uuid, userId } = apiKeys.data;
        commit('UPDATE_DETAILS', {
          apiKey,
          apiSecret,
          uuid,
          userId,
        });
        dispatch('isDialogLoading', false, { root: true });
        dispatch(
          'trackDialogActionsBy',
          apiKeys.data.apiKey && apiKeys.data.apiSecret ? 1 : 0,
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async generateKey(
    { commit, dispatch },
    payload: { name: string; customerId?: number; key?: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IAPIKeys>>(await generateAPIKey(payload));
      response$.subscribe((apiKeys) => {
        const { apiKey, apiSecret, uuid, userId } = apiKeys.data;
        commit('UPDATE_DETAILS', {
          apiKey,
          apiSecret,
          uuid,
          userId,
        });
        dispatch('snackBarMessage', `API Access Keys generated successfully`, {
          root: true,
        });

        dispatch('snackBarVisibility', true, { root: true });
        dispatch('isLoading', false, { root: true });
        if (!payload.key) {
          dispatch(
            'updateDialog',
            { idx: 'details', state: true },
            { root: true }
          );
        }
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async revokeKeys({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id } = payload;
      const response$ = of<IResponse<IAPIKeys>>(await revokeKeys(id));
      response$.subscribe(() => {
        dispatch('snackBarMessage', `API Access Keys revoked successfully`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('isLoading', false, { root: true });
      });
      if (payload.key) {
        dispatch('apiKeyByCustomerID', payload?.customerId);
      } else {
        dispatch('details');
        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
      }
      dispatch('trackDialogActionsBy', 0, { root: true }); // for determining when to show the clipboard icon on the dialog
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IAPIKeys>, RootState> = {
  getAPIKeys: (state) => state.details,
};

export const apiKeys: Module<BaseState<IAPIKeys>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
