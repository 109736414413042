import { Api } from './api';

export const topUpHistory = <T>(
  subAccountId: number,
  page: number,
  limit: number,
  query: string
): Promise<T> => {
  return Api().get(`/history/top-up?page=${page}&limit=${limit}${query}`, {
    headers: {
      ...(subAccountId && {
        'x-account-id': subAccountId,
      }),
    },
  });
};
