import { Api } from './api';
import { IScheduledEvent } from '@/types/types';

export const event = <T>(query: string): Promise<T> => {
  return Api().get(`/events${query ? query : ''}`);
};

export const details = <T>(id: number): Promise<T> => {
  return Api().get(`/events/${id}`);
};

export const scheduleEvent = <T>(payload: IScheduledEvent): Promise<T> => {
  return Api().post(`/events/schedule`, payload);
};

export const updateEvent = <T>(
  id: number,
  payload: IScheduledEvent
): Promise<T> => {
  return Api().put(`/events/${id}`, payload);
};

export const deleteEvent = <T>(id: number): Promise<T> => {
  return Api().delete(`/events/${id}`);
};
