import { BaseState, ILogsCount, IResponse } from '@/types/types';
import { isNetworkError } from '@/utils/helpers';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { RootState, IDashboardCount } from '../../types/types';
import { of } from 'rxjs';
import {
  dailyDeliveryStats,
  dashboardCount,
} from '@/services/dashboard.service';
import { map } from 'rxjs/operators';

const state: BaseState<any> = {
  list: [],
  details: null,
  counts: null,
  dailyReports: [],
};

const mutations: MutationTree<BaseState<any>> = {
  UPDATE_DASHBOARD_COUNT(state, payload) {
    state.counts = payload;
  },
  UPDATE_DAILY_REPORT(state, payload) {
    state.dailyReports = payload;
  },
};

const actions: ActionTree<BaseState<any>, RootState> = {
  async dashboardCount({ commit, dispatch }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IDashboardCount>>(
        await dashboardCount()
      ).pipe(map((response) => response.data));
      response$.subscribe((counts) => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_DASHBOARD_COUNT', counts);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async dailyDeliveryStats({ commit, dispatch }, query: string) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<[{ key: string; logs: ILogsCount }]>>(
        await dailyDeliveryStats(query ?? '')
      ).pipe(map((report) => report.data));
      response$.subscribe((counts) => {
        dispatch('isDialogLoading', false, { root: true });
        commit('UPDATE_DAILY_REPORT', counts);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<any>, RootState> = {
  getDashboardCount: (state) => state.counts,
  getDailyDeliveryStats: (state) => state.dailyReports,
};

export const reports: Module<BaseState<any>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
