import { Api } from './api';
import { IReport } from '../types/types';

export const logs = <T>(
  page: number,
  limit: number,
  query: string,
  subAccountId?: number
): Promise<T> => {
  return Api().get(`/logs?page=${page}&limit=${limit}${query}`, {
    headers: {
      ...(subAccountId && {
        'x-account-id': subAccountId,
      }),
    },
  });
};

export const details = <T>(id: number): Promise<T> => {
  return Api().get(`/logs/${id}`);
};

export const sendFeedback = <T>(payload: IReport): Promise<T> => {
  return Api().post(`/logs/provide/feedback`, payload);
};

export const bugReport = <T>(
  payload: IReport & { screenshot: string }
): Promise<T> => {
  return Api().post(`/logs/report/bug`, payload);
};

export const listAllFileDownloads = <T>(
  page: number,
  limit: number,
  accountId: number | undefined,
  query: string
): Promise<T> => {
  return Api().get(
    `/logs/downloaded/files?page=${page}&limit=${limit}${query ? query : ''}`,
    {
      headers: {
        ...(accountId && {
          'x-account-id': accountId,
        }),
      },
    }
  );
};

export const scheduleLogsDownload = <T>(
  accountId: number | undefined,
  query: string
): Promise<T> => {
  return Api().get(`/logs/schedule/report${query ? query : ''}`, {
    headers: {
      ...(accountId && {
        'x-account-id': accountId,
      }),
    },
    responseType: 'json',
  });
};

export const downloadLogReport = <T>(
  accountId: number | undefined,
  id: number
): Promise<T> => {
  return Api().get(`/logs/download/report/${id}`, {
    headers: {
      ...(accountId && {
        'x-account-id': accountId,
      }),
    },
  });
};
export const deleteDownloadedFile = <T>(
  accountId: number | undefined,
  id: number
): Promise<T> => {
  return Api().delete(`/logs/download/report/${id}`, {
    headers: {
      ...(accountId && {
        'x-account-id': accountId,
      }),
    },
  });
};
