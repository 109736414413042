import { IGroup } from '@/types/types';
import { Api } from './api';
import { IContact } from '../types/types';

export const groups = <T>(
  isPaginated: boolean,
  page: number,
  limit: number
): Promise<T> => {
  return Api().get(
    `/groups?isPaginated=${isPaginated}&page=${page}&limit=${limit}`
  );
};

export const details = <T>(id: number): Promise<T> => {
  return Api().get(`/groups/${id}`);
};

export const addGroup = <T>(payload: IGroup): Promise<T> => {
  return Api().post(`/groups`, payload);
};

export const updateGroup = <T>(
  id: number,
  payload: Pick<IGroup, 'name' | 'description'>
): Promise<T> => {
  return Api().put(`/groups/${id}`, payload);
};

export const removeGroup = <T>(id: number): Promise<T> => {
  return Api().delete(`/groups/${id}`);
};

export const uploadContactToGroup = <T>(
  id: number,
  payload: FormData
): Promise<T> => {
  return Api().post(`/groups/${id}/upload/contacts`, payload);
};

export const addContactsToGroup = <T>(
  id: number,
  group: { contacts: IContact[] }
): Promise<T> => {
  return Api().post(`/groups/${id}/assign/contacts`, group);
};

export const searchGroups = <T>(query: string): Promise<T> => {
  return Api().get(`/groups/search/list?query=${query}`);
};
