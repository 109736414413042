import { Api } from './api';
import {
  IResetPassword,
  IUser,
  IShareCredit,
  ITransferCredit,
} from '../types/types';

export const listSubAccounts = <T>(query: string): Promise<T> => {
  // query = ?page=1&size=15&searchTerm=lkl
  return Api().get(`/users/list/sub-accounts${query ? query : ''}`);
};

export const rawListSubAccounts = <T>(): Promise<T> => {
  return Api().get(`/users/list/sub-accounts/by/master`);
};

export const completeAccountSetUp = <T>(
  payload: IResetPassword & { username: string }
): Promise<T> => {
  return Api().put(`/users/create/sub-accounts/completion`, payload);
};

export const setUpSubAccount = <T>(
  payload: Pick<IUser, 'name' | 'email' | 'phone'>
): Promise<T> => {
  return Api().post(`/users/create/sub-accounts`, payload);
};

export const deleteSubAccount = <T>(id: number): Promise<T> => {
  return Api().delete(`/users/${id}`);
};

export const changeSubAccountStatus = <T>(body: {
  isActive: boolean;
}): Promise<T> => {
  return Api().put(`/users/account/change/status`, body);
};

export const shareCreditAcrossSubAccount = <T>(
  body: IShareCredit
): Promise<T> => {
  return Api().put(`/users/sub-accounts/share/credit`, body);
};

export const customerDetails = <T>(id: number): Promise<T> => {
  return Api().get(`/users/${id}`);
};

export const updateCustomerProfile = <T>(
  id: number,
  body: IUser
): Promise<T> => {
  return Api().put(`/users/${id}`, body);
};

export const transferCredit = <T>(body: ITransferCredit): Promise<T> => {
  return Api().post(`/users/sub-accounts/transfer/credit`, body);
};

export const sendPasswordResetLink = <T>(body: {
  email: string;
}): Promise<T> => {
  return Api().post('/auth/account/forgot-password', body);
};
