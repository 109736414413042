import { BaseState, IResponse } from '@/types/types';
import { IMessage, RootState, ISenderID } from '../../types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  quickSMS,
  smsType,
  bulkSMS,
  quickSMSToAllContacts,
  newSenderID,
  listSenderID,
  detailsSenderID,
  deleteSenderID,
  listPlaceholders,
  generateTemplate,
  sendPersonalizeSMS,
} from '../../services/sms.service';

const state: BaseState<IMessage> = {
  list: [],
  details: {
    to: '',
    from: '',
    message: '',
    type: 'Quick',
  },
  downloadUrl: null,
  senderIDs: [],
  pendingSenderIDs: [],
  placeholders: [],
  senderIDDetails: {
    id: 0,
    uuid: '',
    slug: '',
    name: '',
  },
  resetForm: {
    to: '',
    from: '',
    message: '',
    type: 'Quick',
  },
};

const mutations: MutationTree<BaseState<IMessage>> = {
  UPDATE_LIST(state, payload: Array<IMessage>) {
    state.list = payload;
  },
  UPDATE_PLACEHOLDERS(state, payload: string[]) {
    state.placeholders = payload;
  },
  UPDATE_SENDER_ID(
    state,
    payload: Array<ISenderID & { id: number; slug: string; uuid: string }>
  ) {
    state.senderIDs = payload;
  },
  UPDATE_PENDING_SENDER_ID(
    state,
    payload: Array<ISenderID & { id: number; slug: string; uuid: string }>
  ) {
    state.pendingSenderIDs = payload;
  },
  UPDATE_SENDER_ID_DETAILS(
    state,
    payload: ISenderID & { id: number; slug: string; uuid: string }
  ) {
    state.senderIDDetails = payload;
  },
  UPDATE_TEMPLATE_URL(state, payload: string) {
    state.downloadUrl = payload;
  },
};

const actions: ActionTree<BaseState<IMessage>, RootState> = {
  async sendQuickSMS(
    { dispatch, rootState },
    payload: {
      body: IMessage;
      type: smsType;
      subAccountId: Record<string, unknown>;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<boolean>>(
        await quickSMS(
          { ...payload.body, isGlobal: rootState.isGlobal as boolean },
          payload.type,
          payload?.subAccountId
        )
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Sms message(s) successfully sent`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'details', state: false },
          { root: true }
        );
        setTimeout(() => {
          dispatch(
            'checkCreditBalance',
            { subAccountId: payload?.subAccountId },
            { root: true }
          );
        }, 4000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async sendQuickSMSToAllContacts({ dispatch }, payload: { body: IMessage }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<boolean>>(
        await quickSMSToAllContacts(payload.body)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Sms message(s) successfully sent`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'contacts', state: false },
          { root: true }
        );
        setTimeout(() => {
          dispatch('checkCreditBalance', null, { root: true });
        }, 4000);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async sendBulkSMS(
    { dispatch, rootState },
    payload: {
      body: { messages: IMessage[] };
      subAccountId: Record<string, unknown>;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<boolean>>(
        await bulkSMS(
          { ...payload.body, isGlobal: rootState.isGlobal as boolean },
          payload?.subAccountId
        )
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Sms message(s) successfully sent`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'details', state: false },
          { root: true }
        );
        setTimeout(() => {
          dispatch('checkCreditBalance', null, { root: true });
          dispatch('users/rawList', null, { root: true });
        }, 4500);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async listSenderID({ dispatch, commit }, payload: { isApproved: boolean }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const query = `?isApproved=${payload?.isApproved ?? true}`;
      const response$ = of<
        IResponse<Array<ISenderID> & { id: number; slug: string; uuid: string }>
      >(await listSenderID(query));
      response$.subscribe((senders) => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_SENDER_ID', senders.data);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async senderIDDetails({ dispatch, commit }, id: number) {
    try {
      const response$ = of<
        IResponse<ISenderID & { id: number; slug: string; uuid: string }>
      >(await detailsSenderID(id));
      response$.subscribe((details) => {
        commit('UPDATE_SENDER_ID_DETAILS', details.data);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async pendingSenderID({ dispatch, commit }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<
        IResponse<Array<ISenderID> & { id: number; slug: string; uuid: string }>
      >(await listSenderID(`?isApproved=false`));
      response$.subscribe((senders) => {
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_PENDING_SENDER_ID', senders.data);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async createSenderID({ dispatch }, payload: ISenderID) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<ISenderID>>(await newSenderID(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'senderId', state: false },
          { root: true }
        );
        dispatch(
          'updateDialog',
          { idx: 'confirm', state: true },
          { root: true }
        );
        dispatch('pendingSenderID');
        dispatch(
          'snackBarMessage',
          `Sender ID created successfully. Notice has been sent to your email.`,
          {
            root: true,
          }
        );
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async deleteSenderID(
    { dispatch, state, commit },
    payload: ISenderID & {
      id: number;
      slug: string;
      uuid: string;
      isApproved: boolean;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<ISenderID>>(
        await deleteSenderID(payload.id)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarMessage', `Sender ID deleted successfully.`, {
          root: true,
        });
        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
        dispatch('snackBarVisibility', true, { root: true });
        if (payload.isApproved) {
          const approvedList = (state as any).senderIDs.filter(
            (sender: ISenderID & { id: number }) => sender.id !== payload.id
          );
          commit('UPDATE_SENDER_ID', approvedList);
        } else {
          const pendingList = (state as any).pendingSenderIDs.filter(
            (sender: ISenderID & { id: number }) => sender.id !== payload.id
          );
          commit('UPDATE_PENDING_SENDER_ID', pendingList);
        }
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async listPlaceholders({ dispatch, commit }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<{ fieldNames: string[] }[]>>(
        await listPlaceholders()
      ).pipe(map((response) => response.data));
      response$.subscribe((results) => {
        dispatch('isLoading', false, { root: true });
        const placeholders = new Set(
          results
            .map(({ fieldNames }) => fieldNames)
            .reduce((acc, cur) => {
              acc.push(...cur);
              return acc;
            }, [])
        );
        commit('UPDATE_PLACEHOLDERS', [...placeholders]);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async generateCustomizeMessageTemplate(
    { dispatch, commit },
    payload: { message: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<
        IResponse<{ downloadUrl: string; success: boolean }>
      >(await generateTemplate(payload)).pipe(map((response) => response.data));
      response$.subscribe((results) => {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_TEMPLATE_URL', results?.downloadUrl);
      });
    } catch (e) {
      dispatch('isLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
  async personalizeSMS(
    { dispatch },
    payload: { body: FormData; senderId: string }
  ) {
    try {
      dispatch('inProgress', true, { root: true });
      const response$ = of<IResponse<boolean>>(
        await sendPersonalizeSMS(payload.body, payload.senderId)
      ).pipe(map((response) => response.data));
      response$.subscribe(() => {
        dispatch('inProgress', false, { root: true });
        dispatch('snackBarMessage', `Personalize SMS scheduled successfully.`, {
          root: true,
        });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      dispatch('inProgress', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response?.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IMessage>, RootState> = {
  getResetFormValues: (state) => state.resetForm,
  getSenderIDList: (state) => state.senderIDs,
  getPendingSenderIDList: (state) => state.pendingSenderIDs,
  getSenderIDDetails: (state) => state.senderIDDetails,
  getMessagePlaceholders: (state) => state.placeholders,
  getTemplateDownloadUrl: (state) => state.downloadUrl,
};

export const sms: Module<BaseState<IMessage>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
