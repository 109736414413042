import { Api } from './api';
export const apiKey = <T>(): Promise<T> => {
  return Api().get(`/api-keys`);
};

export const generateAPIKey = <T>(payload: { name: string }): Promise<T> => {
  return Api().post(`/api-keys/generate`, payload);
};

export const revokeKeys = <T>(id: number): Promise<T> => {
  return Api().delete(`/api-keys/revoke/${id}`);
};

export const apiKeyByCustomerId = <T>(customerId: number): Promise<T> => {
  return Api().get(`/api-keys/${customerId}`);
};
