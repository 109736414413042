import { loadView, loadWidget } from '@/utils/helpers';

export const paths = [
  {
    path: '/',
    redirect: '/signin',
  },
  {
    path: '/signin',
    name: 'accounts.signin',
    component: loadView('greeters/Login'),
    meta: {
      pageTitle: 'Sign In',
      requiresAuth: false,
    },
  },
  {
    path: '/terms',
    name: 'accounts.terms',
    component: loadView('greeters/TermsAndConditions'),
    meta: {
      pageTitle: 'Terms Of Service',
      requiresAuth: false,
    },
  },
  {
    path: '/privacy-policy',
    name: 'accounts.privacy-policy',
    component: loadView('greeters/PrivacyPolicy'),
    meta: {
      pageTitle: 'Privacy Policy',
      requiresAuth: false,
    },
  },
  {
    path: '/signup/options',
    name: 'accounts.signup.options',
    component: loadView('greeters/SignUpOptions'),
    meta: {
      pageTitle: 'Sign Up Options',
      requiresAuth: false,
    },
  },
  {
    path: '/register/individual',
    name: 'accounts.signup.developer',
    component: loadView('greeters/RegisterAsDeveloper'),
    meta: {
      pageTitle: 'Sign Up',
      requiresAuth: false,
    },
  },
  {
    path: '/register/business',
    name: 'accounts.signup.business',
    component: loadView('greeters/RegisterAsBusiness'),
    meta: {
      pageTitle: 'Sign Up',
      requiresAuth: false,
    },
  },
  {
    path: '/verify/account',
    name: 'accounts.verification',
    component: loadView('greeters/VerifyAccount'),
    meta: {
      pageTitle: 'Account Verification',
      requiresAuth: false,
    },
  },
  {
    path: '/migrate/account',
    name: 'accounts.migration',
    component: loadView('greeters/MigrateAccount'),
    meta: {
      pageTitle: 'Account Migration',
      requiresAuth: false,
    },
  },
  {
    path: '/forgot-password',
    name: 'accounts.forgot-password',
    component: loadView('greeters/ForgotPassword'),
    meta: {
      pageTitle: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/reset-password',
    name: 'accounts.reset-password',
    component: loadView('greeters/ResetPassword'),
    meta: {
      pageTitle: 'Forgot Password',
      requiresAuth: false,
    },
  },
  {
    path: '/account-setup/completion',
    name: 'accounts.setup.completion',
    component: loadView('greeters/SetUpAccounts'),
    meta: {
      pageTitle: 'Account Setup Completion',
      requiresAuth: false,
    },
  },
  {
    path: `/verify/payment`,
    name: `account.verify.payment`,
    component: loadView(`pages/payments/VerifyPayment`),
    meta: {
      pageTitle: `Payment Verification`,
      requiresAuth: true,
    },
  },
  {
    path: '/',
    name: 'account',
    component: loadView('Master'),
    children: [
      {
        path: `/dashboard`,
        name: `account.dashboard`,
        component: loadView('pages/Dashboard'),
        meta: {
          pageTitle: `Dashboard`,
          requiresAuth: true,
        },
      },
      {
        path: `/groups`,
        name: `account.groups`,
        component: loadView(`pages/groups/Index`),
        meta: {
          pageTitle: `Groups`,
          requiresAuth: true,
        },
      },
      {
        path: `/contacts`,
        name: `account.contacts`,
        component: loadView(`pages/contacts/Index`),
        meta: {
          pageTitle: `Contacts`,
          requiresAuth: true,
        },
      },
      {
        path: `/sender/id`,
        name: `account.sender.ids`,
        component: loadView(`pages/sender-id/Index`),
        meta: {
          pageTitle: `Sender IDs`,
          requiresAuth: true,
        },
      },
      {
        path: `/sms/quick`,
        name: `account.sms.quick`,
        component: loadView(`pages/sms/QuickSMS`),
        meta: {
          pageTitle: `Quick SMS`,
          requiresAuth: true,
        },
      },
      {
        path: `/sms/bulk`,
        name: `account.sms.bulk`,
        component: loadView(`pages/sms/BulkSMS`),
        meta: {
          pageTitle: `Bulk SMS`,
          requiresAuth: true,
        },
      },
      {
        path: `/sms/personalize`,
        name: `account.sms.personalize`,
        component: loadView(`pages/sms/PersonalizeSMS`),
        meta: {
          pageTitle: `Personalize SMS`,
          requiresAuth: true,
        },
      },
      {
        path: `/events`,
        name: `account.events.list`,
        component: loadView(`pages/events/Index`),
        meta: {
          pageTitle: `Events`,
          requiresAuth: true,
        },
      },
      {
        path: `/events/:id`,
        name: `account.events.details`,
        component: loadView(`pages/events/Edit`),
        meta: {
          pageTitle: `Edit Event`,
          requiresAuth: true,
        },
      },
      {
        path: `/api-keys`,
        name: `account.api.keys`,
        component: loadView(`pages/developers/APIKeys`),
        meta: {
          pageTitle: `API Keys Management`,
          requiresAuth: true,
        },
      },
      {
        path: `/logs`,
        name: `account.logs`,
        component: loadView(`pages/developers/Logs`),
        meta: {
          pageTitle: `Logs`,
          requiresAuth: true,
        },
      },
      {
        path: `/profile`,
        name: `account.profile`,
        component: loadView(`pages/profile/Index`),
        meta: {
          pageTitle: `Personal Information`,
          requiresAuth: true,
        },
      },
      {
        path: `/change/password`,
        name: `account.change.password`,
        component: loadView(`pages/profile/ChangePassword`),
        meta: {
          pageTitle: `Change Password`,
          requiresAuth: true,
        },
      },
      {
        path: `/sub-accounts`,
        name: `account.sub-accounts`,
        component: loadView(`pages/settings/SubAccounts`),
        meta: {
          pageTitle: `Sub Accounts`,
          requiresAuth: true,
        },
      },
      {
        path: `/sub-accounts/:id/details`,
        name: `account.sub-accounts.details`,
        component: loadView(`pages/settings/SubAccountDetails`),
        meta: {
          pageTitle: `Sub Account Details`,
          requiresAuth: true,
        },
      },
      {
        path: `/sub-accounts/:id/history`,
        name: `account.sub-accounts.history`,
        component: loadView(`pages/reports/TopUpHistory`),
        meta: {
          pageTitle: `Top Up History`,
          requiresAuth: true,
        },
      },
      {
        path: `/sub-accounts/:id/messages`,
        name: `account.sub-accounts.messages`,
        component: loadView(`pages/reports/Messages`),
        meta: {
          pageTitle: `All Sent SMS'`,
          requiresAuth: true,
        },
      },
      {
        path: `/reports/messages`,
        name: `account.messages`,
        component: loadView(`pages/reports/Messages`),
        meta: {
          pageTitle: `All Sent SMS'`,
          requiresAuth: true,
        },
      },
      {
        path: `/reports/payments`,
        name: `account.payments`,
        component: loadView(`pages/reports/Payments`),
        meta: {
          pageTitle: `Payments`,
          requiresAuth: true,
        },
      },
      {
        path: `/reports/top-up/history`,
        name: `account.top-up.history`,
        component: loadView(`pages/reports/TopUpHistory`),
        meta: {
          pageTitle: `Top Up History`,
          requiresAuth: true,
        },
      },
      {
        path: `/reports/downloads`,
        name: `account.downloads`,
        component: loadView(`pages/reports/Downloads`),
        meta: {
          pageTitle: `Downloads`,
          requiresAuth: true,
        },
      },
      {
        path: `/feedback`,
        name: `account.feedback`,
        component: loadView(`pages/help-center/Feedback`),
        meta: {
          pageTitle: `Feedback`,
          requiresAuth: true,
        },
      },
      {
        path: `/report/bug`,
        name: `account.bug.report`,
        component: loadView(`pages/help-center/BugReport`),
        meta: {
          pageTitle: `Bug Report`,
          requiresAuth: true,
        },
      },
    ],
  },

  /**
   * 404 redirect
   */
  {
    path: '*',
    redirect: '/404',
  },
  {
    path: '/404',
    name: '404',
    component: loadWidget('pages/Error404'),
    meta: {
      pageTitle: `Not Found | MSys`,
      requiresAuth: false,
    },
  },
];
