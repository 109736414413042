import {
  RootState,
  ILogs,
  IPagination,
  IRawLogs,
  IReport,
  ILogsGraph,
  IFileDownloads,
} from '../../types/types';
import { BaseState, IPaginate, IResponse } from '@/types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import {
  bugReport,
  deleteDownloadedFile,
  details,
  downloadLogReport,
  listAllFileDownloads,
  logs,
  scheduleLogsDownload,
  sendFeedback,
} from '../../services/logs.service';
import router from '../../router/index';
const state: BaseState<ILogs | IFileDownloads> = {
  list: [],
  details: {
    id: '',
    createdAt: '',
    segments: 0,
    status: '',
    event: '',
    reason: '',
  },
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    limit: 15,
  },
  graph: [],
};

const mutations: MutationTree<BaseState<ILogs | IFileDownloads>> = {
  UPDATE_LIST(state, payload: ILogs[] | IFileDownloads[]) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
  UPDATE_GRAPH(state, payload: Array<ILogsGraph>) {
    state.graph = payload;
  },
};

const actions: ActionTree<BaseState<ILogs | IFileDownloads>, RootState> = {
  async list(
    { commit, dispatch },
    payload: IPaginate & { limit: number; query: string; subAccountId: number }
  ) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { page, limit, query } = payload;
      const response$ = of<IResponse<IPagination<ILogs>>>(
        await logs(page, limit, query ?? '', payload?.subAccountId)
      );
      response$.subscribe((logs) => {
        const { page, limit, pages, total, docs } = logs.data.paginateObj;
        commit('UPDATE_GRAPH', logs.data.others?.graph);
        commit(
          'UPDATE_LIST',
          docs.map((doc) => {
            return {
              ...doc,
              url: `/logs/${doc.id}`,
            };
          })
        );
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async loadMore(
    { dispatch, commit, state },
    payload: IPaginate & { limit: number; query: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const { page, limit, query } = payload;
      const response$ = of<IResponse<IPagination<IRawLogs>>>(
        await logs(page + 1, limit, query ?? '')
      );
      response$.subscribe((logs) => {
        const { page, limit, pages, total, docs } = logs.data.paginateObj;
        commit('UPDATE_LIST', [
          ...state.list,
          ...docs.map((doc) => {
            return {
              id: doc.logs_id,
              method: 'GET',
              url: `/v1/logs/${doc.logs_uuid}`,
              event: doc.logs_event,
              payload: doc.logs_payload,
              reason: doc.logs_reason,
              segments: doc.logs_segments,
              status: doc.logs_status,
              createdAt: doc.logs_createdAt,
              statusCode: doc.deliverySystemLogs_statusCode,
            };
          }),
        ]);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id: number) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<ILogs>>(await details(id));
      response$.subscribe((logs) => {
        commit('UPDATE_DETAILS', logs.data);
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async feedback({ dispatch }, payload: IReport) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IReport>>(await sendFeedback(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch(
          'snackBarMessage',
          `Thank you for providing your feedback. Much appreciated`,
          { root: true }
        );
        router.push({ name: 'account.dashboard' });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async reportBug({ dispatch }, payload: IReport & { screenshot: string }) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IReport & { screenshot: string }>>(
        await bugReport(payload)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch(
          'snackBarMessage',
          `Thank you for reporting this bug. We will do well to get it fixed.`,
          { root: true }
        );
        router.push({ name: 'account.dashboard' });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async scheduleLogsDownload(
    { dispatch },
    payload: {
      subAccountId: number | undefined;
      query: string;
      fileName: string;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<
        IResponse<{
          statusMessage: string;
          statusCode: string;
          data: { status: boolean; totalRecords: number };
        }>
      >(await scheduleLogsDownload(payload.subAccountId, payload.query));
      response$
        .subscribe((response) => {
          dispatch('snackBarVisibility', true, { root: true });
          dispatch('snackBarMessage', response.data.statusMessage, {
            root: true,
          });
          dispatch('isLoading', false, { root: true });
          dispatch('isDownloading', true, { root: true });
          dispatch(
            'totalBulkRecords',
            Number(response.data.data?.totalRecords) ?? 0,
            { root: true }
          );
        })
        .unsubscribe();
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async listAllFileDownloads(
    { dispatch, commit },
    payload: IPaginate & { subAccountId: number; limit: number; query: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      dispatch('isPageLoading', true, { root: true });
      const { page, limit, query } = payload;
      const response$ = of<IResponse<IPagination<IFileDownloads>>>(
        await listAllFileDownloads(page, limit, payload?.subAccountId, query)
      );
      response$
        .subscribe((response) => {
          dispatch('isPageLoading', false, { root: true });
          const { page, limit, pages, total, docs } = response.data.paginateObj;
          commit('UPDATE_LIST', docs);
          commit('UPDATE_PAGINATION', {
            page,
            pages,
            total,
            itemsPerPage: limit,
          });
          dispatch('isLoading', false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async fileDownload(
    { dispatch },
    payload: {
      subAccountId: number | undefined;
      id: number;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<
        IResponse<{ secureFileUrl: string; fileName: string }>
      >(await downloadLogReport(payload.subAccountId, payload.id));
      response$
        .subscribe((response) => {
          const a = document.createElement('a');
          a.download = response.data.fileName;
          a.href = response.data.secureFileUrl;
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          dispatch('isLoading', false, { root: true });
        })
        .unsubscribe();
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async deleteDownloadedFile(
    { dispatch, state },
    payload: {
      subAccountId: number | undefined;
      id: number;
      query?: string;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<
        IResponse<{
          data: IFileDownloads;
          statusCode: number;
          statusMessage: string;
        }>
      >(await deleteDownloadedFile(payload.subAccountId, payload.id));
      response$
        .subscribe((response) => {
          dispatch('listAllFileDownloads', {
            page: (state.paginate as IPaginate)?.page ?? 1,
            limit: (state.paginate as IPaginate)?.limit ?? 15,
            query: payload?.query,
            subAccountId: payload?.subAccountId,
          });
          dispatch('snackBarVisibility', true, { root: true });
          dispatch('snackBarMessage', response.data.statusMessage, {
            root: true,
          });
          dispatch('isLoading', false, { root: true });
          dispatch(
            'updateDialog',
            { idx: 'delete', state: false },
            { root: true }
          );
        })
        .unsubscribe();
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<ILogs | IFileDownloads>, RootState> = {
  getAllLogs: (state) => state.list,
  getLogDetails: (state) => state.details,
  getPagination: (state) => state.paginate,
  getLogsGraph: (state) => state.graph,
};

export const log: Module<BaseState<ILogs | IFileDownloads>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
