












import { mixins } from 'vue-class-component';
import { Component } from 'vue-property-decorator';
import DialogMixins from './mixins/DialogMixins';
import WidgetMixins from './mixins/WidgetMixins';
import { loadWidget } from './utils/helpers';

@Component({
  components: {
    snackBar: loadWidget('widgets/SnackBar'),
    ConfirmationDialog: loadWidget('widgets/ConfirmationDialog'),
  },
})
export default class App extends mixins(DialogMixins, WidgetMixins) {
  checkConnection(): void {
    if (navigator.onLine) {
      this.$store.dispatch('internet', false, { root: true });
    } else {
      this.$store.dispatch('internet', true, { root: true });
    }
  }

  created(): void {
    const isUserLoggedIn = this.$store.getters['auth/isAuthenticated'];
    if (isUserLoggedIn) {
      this.$store.dispatch('auth/me');
    }
    if (process.env.NODE_ENV === 'production') {
      // TODO: have to make request whenever the page detects internet and reloads the page
      setInterval(() => {
        this.checkConnection();
      }, 4000);
    }
  }
}
