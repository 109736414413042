import { BaseState, IResponse, IPaginate, IPagination } from '@/types/types';
import { IContact, RootState } from '../../types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import {
  addContact,
  assignContactToGroup,
  bulkContactDownload,
  bulkContactProcess,
  bulkContactUpload,
  contacts,
  details,
  searchContacts,
  updateContact,
} from '@/services/contacts.service';
import { removeContact, fileDownload } from '../../services/contacts.service';
import { map } from 'rxjs/operators';
import { camelCase } from 'lodash';

const namespaced = true;
export type Paginate = {
  paginate: {
    total: number;
    page: number;
    limit: number;
  };
};

const state: BaseState<IContact> = {
  list: [],
  searchResults: [],
  details: {
    id: '',
    name: '',
    phone: '',
    dateOfBirth: null,
  },
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    limit: 30,
  },
  reset: {
    name: '',
    phone: '',
    dateOfBirth: null,
  },
  file: null,
};

const mutations: MutationTree<BaseState<IContact>> = {
  UPDATE_LIST(state, payload: ReadonlyArray<IContact>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload: IContact) {
    state.details = payload;
  },
  UPDATE_SEARCH_RESULTS(state, payload: ReadonlyArray<IContact>) {
    state.searchResults = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
};

const actions: ActionTree<BaseState<IContact>, RootState> = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { page, limit, sort } = payload;
      const response$ = of<IResponse<IPagination<IContact>>>(
        await contacts(true, page, limit, sort)
      );
      response$.subscribe((contacts) => {
        const { page, limit, pages, total, docs } = contacts.data.paginateObj;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async loadMore(
    { state, commit, dispatch },
    payload: IPaginate & { limit: number; sort?: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const { page, pages, limit, sort } = payload;
      const newPage = pages && page < pages ? page + 1 : page;
      const response$ = of<IResponse<IPagination<IContact>>>(
        await contacts(true, newPage, limit, sort)
      );
      response$.subscribe((contacts) => {
        dispatch('isLoading', false, { root: true });
        const { page, limit, pages, total, docs } = contacts.data.paginateObj;
        commit('UPDATE_LIST', [...state.list, ...docs]);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch(
          'snackBarMessage',
          e.response.data?.message ?? `Unable to get more contacts`,
          {
            root: true,
          }
        );
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IContact>>(await details(id));
      response$.subscribe((contacts) => {
        commit('UPDATE_DETAILS', contacts.data);
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async create({ state, dispatch }, payload: IContact) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IContact>>(await addContact(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contact added successfully`, {
          root: true,
        });
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async update({ state, dispatch }, payload: IContact) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id, ...rest } = payload;
      const response$ = of<IResponse<IContact>>(
        await updateContact(Number(id), rest)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contact updated successfully`, {
          root: true,
        });
        dispatch('updateDialog', { idx: 'edit', state: false }, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async delete({ dispatch, state }, id) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IContact>>(await removeContact(id));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contact deleted successfully`, {
          root: true,
        });
        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async assignToGroup(
    { dispatch, state },
    payload: { id: number; groups: number[] }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IContact>>(
        await assignContactToGroup(payload.id, payload.groups)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contact assigned to group successfully`, {
          root: true,
        });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async searchContacts(
    { dispatch, commit, state },
    payload: {
      query: string;
      page: number;
      limit: number;
      isPaginated: boolean;
      sort?: string;
    }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const { query, page, limit, isPaginated, sort } = payload;
      const response$ = of<IResponse<IPagination<IContact>>>(
        await searchContacts(query, page, limit, isPaginated, sort)
      );
      response$.subscribe(
        (data: IResponse<IPagination<IContact>> | IResponse<IContact>) => {
          dispatch('isLoading', false, { root: true });
          if (isPaginated) {
            const {
              page: currentPage,
              limit: pageLimit,
              pages,
              total,
              docs,
            } = (data as IResponse<IPagination<IContact>>).data.paginateObj;
            commit('UPDATE_LIST', page > 1 ? [...state.list, ...docs] : docs);
            commit('UPDATE_PAGINATION', {
              page: currentPage,
              pages,
              total,
              itemsPerPage: pageLimit,
            });
          } else {
            commit('UPDATE_LIST', (data as IResponse<IContact>).data);
          }
        }
      );
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  async fileDownload({ commit }, payload: { fileName: string; ext: string }) {
    const response$ = of<IResponse<Blob>>(await fileDownload(payload));
    response$
      .subscribe((response) => {
        const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          }),
          a = document.createElement('a');

        a.download = `${payload.fileName}.${payload.ext}`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);
      })
      .unsubscribe();
  },
  async bulkUpload({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<ReadonlyArray<IContact>>>(
        await bulkContactUpload(payload)
      ).pipe(map((contacts) => contacts.data));
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      response$.subscribe((contacts) => {
        dispatch('isLoading', false, { root: true });
        dispatch('inProgress', true, { root: true });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch(
          'snackBarMessage',
          `Batch contact upload scheduled successfully`,
          {
            root: true,
          }
        );
        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'upload', state: false },
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async processContactsFile({ commit, dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<ReadonlyArray<IContact>>>(
        await bulkContactProcess(payload)
      ).pipe(map((contacts) => contacts.data));
      response$.subscribe((contacts) => {
        commit('UPDATE_LIST', contacts);
        dispatch('isLoading', false, { root: true });
        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'upload', state: false },
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async bulkContactDownload(
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    { commit, dispatch },
    payload: { fileName: string; fields: ReadonlyArray<string> }
  ) {
    dispatch('isLoading', true, { root: true });
    const response$ = of<IResponse<Blob>>(
      await bulkContactDownload(
        payload.fileName,
        payload.fields?.map((field) => camelCase(field))
      )
    );
    response$
      .subscribe((response) => {
        console.log(response.data);
        const blob = new Blob([response.data], {
            type: 'application/octet-stream',
          }),
          a = document.createElement('a');
        a.download = `${payload.fileName}`;
        a.href = window.URL.createObjectURL(blob);
        a.click();
        window.URL.revokeObjectURL(a.href);
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contacts exported successfully`, {
          root: true,
        });
        dispatch('isLoading', false, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'download', state: false },
          { root: true }
        );
      })
      .unsubscribe();
  },
};

const getters: GetterTree<BaseState<IContact>, RootState> = {
  getPagination: (state) => state.paginate,
  getContactDetails: (state) => state.details,
  getAllContacts: (state) => state.list,
  getResetFormValues: (state) => state.reset,
  getResetFile: (state) => state.file,
  getSearchResults: (state) => state.searchResults,
};

export const contact: Module<BaseState<IContact>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
