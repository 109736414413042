/* eslint-disable @typescript-eslint/no-non-null-assertion */
import Vue from 'vue';
import Vuex, { ActionTree, GetterTree, MutationTree, StoreOptions } from 'vuex';
import { IResponse, PayloadState, RootState } from '../types/types';
import createPersistedState from 'vuex-persistedstate';
import SecureLS from 'secure-ls';
import { auth } from './modules/auth';
import { apiKeys } from './modules/api-keys';
import { contact } from './modules/contacts';
import { group } from './modules/groups';
import { log } from './modules/logs';
import { sms } from './modules/sms';
import { creditBalance } from '@/services/sms.service';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { payments } from './modules/payments';
import { reports } from './modules/reports';
import { users } from './modules/users';
import { events } from './modules/events';
import { history } from './modules/history';
import { ICreditBalance } from '@/types/types';

Vue.use(Vuex);
const ls = new SecureLS({
  isCompression: false,
});

const mutations: MutationTree<RootState> = {
  UPDATE_SNACKBAR_MSG(state, payload: string) {
    state.snackBar!.message = payload;
  },
  UPDATE_SNACKBAR_VISIBILITY(state, payload: boolean) {
    state.snackBar!.show = payload;
  },
  UPDATE_ISLOADING(state, payload: boolean) {
    state.isLoading = payload;
  },
  UPDATE_ISPAGELOADING(state, payload: boolean) {
    state.isPageLoading = payload;
  },
  UPDATE_ISDIALOGLOADING(state, payload: boolean) {
    state.isDialogLoading = payload;
  },
  UPDATE_IN_PROGRESS(state, payload: boolean) {
    state.inProgress = payload;
  },
  UPDATE_IS_DOWNLOADING(state, payload: boolean) {
    state.isDownloading = payload;
  },
  UPDATE_DIALOG(state, payload: PayloadState) {
    state.dialogs[payload.idx] = payload.state;
  },
  UPDATE_INTERNET_STATE(state, payload: boolean) {
    state.internet = payload;
  },
  UPDATE_RESET_FORM(state, payload: boolean) {
    state.resetForm = payload;
  },
  UPDATE_NOT_FOUND(state, payload: boolean) {
    state.notFound = payload;
  },
  UPDATE_NAVIGATION_DRAWER(state, payload: boolean) {
    state.navigationDrawer = payload;
  },
  UPDATE_BALANCE_LOADER(state, payload: boolean) {
    state.isBalanceLoading = payload;
  },
  UPDATE_BALANCE(state, payload: ICreditBalance) {
    state.creditBalance = payload;
  },
  UPDATE_WINDOW_SIZE(state, size: number | null) {
    state.windowSize = size;
  },
  UPDATE_REDIRECT_URL(state, url: string) {
    state.redirectUrl = url;
  },
  CANCEL_ALL_DIALOGS(state) {
    state.dialogs = {
      add: false,
      edit: false,
      delete: false,
      details: false,
      contacts: false,
      upload: false,
      payment: false,
      senderId: false,
      confirm: false,
      download: false,
      help: false,
    };
  },
  UPDATE_TRACK_DIALOG_ACTIONS(state, count: number) {
    state.trackDialogAction = count;
  },
  UPDATE_INTL_ROUTE(state, value: boolean) {
    state.isGlobal = value;
  },
  UPDATE_TIMEOUT(state, value: number) {
    state.timeout = value;
  },
  UPDATE_TOTAL_BULK_RECORDS(state, value: number) {
    state.totalBulkRecords = value;
  },
};

const actions: ActionTree<RootState, RootState> = {
  snackBarMessage({ commit }, payload: boolean) {
    commit('UPDATE_SNACKBAR_MSG', payload);
  },
  snackBarVisibility({ commit }, payload: boolean) {
    commit('UPDATE_SNACKBAR_VISIBILITY', payload);
  },
  isLoading({ commit }, payload) {
    commit('UPDATE_ISLOADING', payload);
  },
  isPageLoading({ commit }, payload) {
    commit('UPDATE_ISPAGELOADING', payload);
  },
  inProgress({ commit }, payload: boolean) {
    commit('UPDATE_IN_PROGRESS', payload);
  },
  isDownloading({ commit }, payload: boolean) {
    commit('UPDATE_IS_DOWNLOADING', payload);
  },
  updateDialog({ commit }, payload: PayloadState) {
    commit('UPDATE_DIALOG', payload);
    if (!['image', 'delete'].includes(payload.idx)) {
      commit('UPDATE_ISDIALOGLOADING', true);
    }
  },
  internet({ commit }, payload: boolean) {
    commit('UPDATE_INTERNET_STATE', payload);
  },
  isDialogLoading({ commit }, payload: boolean) {
    commit('UPDATE_ISDIALOGLOADING', payload);
  },
  isBalanceLoading({ commit }, payload: boolean) {
    commit('UPDATE_BALANCE_LOADER', payload);
  },
  resetFormValues({ commit }, payload: boolean) {
    commit('UPDATE_RESET_FORM', payload);
  },
  paginate({ commit }, payload) {
    commit('UPDATE_PAGE', payload);
  },
  currentPage({ commit }, payload) {
    commit('UPDATE_CURRENT_PAGE', payload);
  },
  notFound({ commit }, payload) {
    commit('UPDATE_NOT_FOUND', payload);
  },
  navigationDrawer({ commit }, payload: boolean) {
    commit('UPDATE_NAVIGATION_DRAWER', payload);
  },
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async checkCreditBalance({ commit, dispatch }, payload: any) {
    try {
      dispatch('isBalanceLoading', true, { root: true });
      const headers = {
        ...(payload?.subAccountId && {
          'x-subaccount-id': payload?.subAccountId,
        }),
      };

      const response$ = of<IResponse<{ id: number; credit: number }>>(
        await creditBalance(headers)
      ).pipe(map((results) => results.data));
      response$.subscribe((balance) => {
        dispatch('isBalanceLoading', false, { root: true });
        //commit('UPDATE_BALANCE_DETAILS', balance);
        commit('UPDATE_BALANCE', balance);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isBalanceLoading', false, { root: true });
    }
  },
  async updateCreditBalance(
    { commit },
    balance: { credit: number; intlCredit: number; id: number }
  ) {
    commit('UPDATE_BALANCE', balance);
  },
  async updateWindowSize({ commit }, size: number | null) {
    commit('UPDATE_WINDOW_SIZE', size);
  },
  async closeAllDialogs({ commit }) {
    commit('CANCEL_ALL_DIALOGS');
  },
  async trackDialogActionsBy({ commit }, count: number) {
    commit('UPDATE_TRACK_DIALOG_ACTIONS', count);
  },
  async useIntlRoute({ commit }, value: boolean) {
    commit('UPDATE_INTL_ROUTE', value);
  },
  async totalBulkRecords({ commit }, value: number) {
    commit('UPDATE_TOTAL_BULK_RECORDS', value);
  },
};

const getters: GetterTree<RootState, RootState> = {
  getIsLoading: (state): boolean => state.isLoading,
  getIsPageLoading: (state): boolean => state.isPageLoading,
  getInProgress: (state): boolean => state.inProgress,
  getIsDialogLoading: (state): boolean => state.isDialogLoading as boolean,
  getIsDownloading: (state) => state.isDownloading as boolean,
  getTotalBulkRecords: (state) => state.totalBulkRecords as number,
  getSnackBarMessage: (state): string => state.snackBar!.message,
  showSnackBar: (state): boolean => state.snackBar!.show,
  getDialogState: (state) => (id: string) => state.dialogs[id],
  getIsBalanceLoading: (state) => state.isBalanceLoading,
  getInternet: (state): boolean => state.internet,
  getResetFormState: (state): boolean => state.resetForm as boolean,
  getNotFound: (state) => state.notFound,
  getNavigationDrawerState: (state) => state.navigationDrawer,
  getCreditBalance: (state) => state.creditBalance,
  getWindowSize: (state) => state.windowSize,
  getTrackDialogAction: (state) => state.trackDialogAction,
  getUseIntlRoute: (state) => state.isGlobal,
};
/**
 * Vuex store initial state
 */
const store: StoreOptions<RootState> = {
  strict: process.env.NODE_ENV !== 'production',
  plugins: [
    createPersistedState({
      key: 'cloud.access',
      paths: ['auth.user', 'isGlobal', 'totalBulkRecords', 'isDownloading'],
      storage: {
        getItem: (key) => ls.get(key),
        setItem: (key, value) => ls.set(key, value),
        removeItem: (key) => ls.remove(key),
      },
    }),
  ],
  state: {
    isLoading: false,
    isPageLoading: false,
    isDialogLoading: false,
    isBalanceLoading: false,
    inProgress: false,
    isDownloading: false,
    creditBalance: {
      credit: 0,
      totalAmount: 0,
      intlCredit: 0,
      intlTotalAmount: 0,
    },
    snackBar: {
      message: '',
      status: 'success',
      show: false,
    },
    timeout: 4000,
    internet: false,
    resetForm: false,
    dialogs: {
      add: false,
      edit: false,
      delete: false,
      details: false,
      contacts: false,
      upload: false,
      payment: false,
      senderId: false,
      confirm: false,
      download: false,
      help: false,
    },
    paginate: {
      currentPage: 1,
      page: 0,
      limit: 15,
    },
    notFound: false,
    navigationDrawer: false,
    windowSize: null,
    redirectUrl: '',
    trackDialogAction: 0,
    isGlobal: false,
    totalBulkRecords: 0,
    balance: {
      credit: 0,
      totalAmount: 0,
      intlCredit: 0,
      intlTotalAmount: 0,
    },
  },
  actions,
  mutations,
  getters,
  modules: {
    auth,
    apiKeys,
    contact,
    group,
    log,
    sms,
    payments,
    reports,
    users,
    events,
    history,
  },
};
export default new Vuex.Store<RootState>(store);
