import {
  BaseState,
  ICheckout,
  IPaginate,
  IPagination,
  IPayments,
  IResponse,
} from '@/types/types';
import { isNetworkError } from '@/utils/helpers';
import { Module, MutationTree, ActionTree, GetterTree } from 'vuex';
import { RootState } from '../../types/types';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { initiatePayment, verifyPayment } from '@/services/payments.service';
import { listPayments } from '../../services/payments.service';
const state: BaseState<IPayments> = {
  list: [],
  invoices: [],
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    limit: 15,
  },
  details: {
    channels: 'mobile_money',
    amount: 0,
    reference: '',
    transactionId: '',
    status: '',
    reason: '',
    id: 0,
  },
  resetForm: {
    channels: 'mobile_money',
    amount: 0,
    reference: '',
    transactionId: '',
    status: '',
    reason: '',
    id: 0,
  },
};

const mutations: MutationTree<BaseState<IPayments>> = {
  UPDATE_PAYMENTS(state, payload: IPayments[]) {
    state.list = payload;
  },
  UPDATE_PAYMENT_DETAILS(state, payload: IPayments) {
    state.details = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
};

const actions: ActionTree<BaseState<IPayments>, RootState> = {
  async listPayments(
    { commit, dispatch },
    payload: IPaginate & { limit: number; query: string }
  ) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IPagination<IPayments>>>(
        await listPayments(payload.page, payload.limit, payload.query)
      );
      response$.subscribe(({ data }) => {
        const { page, limit, pages, total, docs } = data.paginateObj;
        dispatch('isPageLoading', false, { root: true });
        commit('UPDATE_PAYMENTS', docs);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        console.log(data);
      });
    } catch (e) {
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async initiatePayment(
    { dispatch },
    payload: Pick<IPayments, 'amount' | 'channels'> & { email: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IResponse<ICheckout>>>(
        await initiatePayment(payload)
      ).pipe(map((response) => response.data));
      response$.subscribe((response: IResponse<ICheckout>) => {
        dispatch('isLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
        dispatch('isPageLoading', false, { root: true });

        dispatch('resetFormValues', true, { root: true });
        dispatch(
          'updateDialog',
          { idx: 'payment', state: false },
          { root: true }
        );
        setTimeout(() => {
          const a = document.createElement('a');
          a.href = response.data.authorization_url;
          a.target = '_blank';
          document.body.appendChild(a);
          a.click();
        }, 1500);
      });
    } catch (e) {
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async verifyPayment({ commit, dispatch }, transactionId: string) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IResponse<IPayments>>>(
        await verifyPayment(transactionId)
      ).pipe(map((response) => response.data));
      response$.subscribe((response) => {
        dispatch('isLoading', false, { root: true });
        const { status, reference, amount } = response.data;
        commit('UPDATE_PAYMENT_DETAILS', {
          status,
          amount,
          reference,
          transactionId: reference,
        });
        setTimeout(() => {
          dispatch('checkCreditBalance', null, { root: true });
          window.close();
        }, 2000);
      });
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    } catch (e: any) {
      commit('UPDATE_PAYMENT_DETAILS', { reason: e.response.data?.message });
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IPayments>, RootState> = {
  getPaymentList: (state) => state.list,
  getPagination: (state) => state.paginate,
  getPaymentResponse: (state) => state.details,
  getResetFormValues: (state) => state.resetForm,
};

export const payments: Module<BaseState<IPayments>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
