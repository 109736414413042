import {
  RootState,
  IPagination,
  ILogsGraph,
  IHistory,
} from '../../types/types';
import { BaseState, IPaginate, IResponse } from '@/types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import { topUpHistory } from '../../services/history.service';
import { TOP_UP_HISTORY_TITLE } from '../../data/constants';

const state: BaseState<IHistory> = {
  list: [],
  details: {
    credit: 0,
    recipient: null,
  },
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    limit: 15,
  },
  graph: [],
  title: [],
};

const mutations: MutationTree<BaseState<IHistory>> = {
  UPDATE_LIST(state, payload: IHistory[]) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload) {
    state.details = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
  UPDATE_GRAPH(state, payload: Array<ILogsGraph>) {
    state.graph = payload;
  },
  UPDATE_TITLE(state, payload) {
    state.title = payload;
  },
};

const actions: ActionTree<BaseState<IHistory>, RootState> = {
  async listAllHistory(
    { commit, dispatch },
    payload: IPaginate & { limit: number; query: string; subAccountId: number }
  ) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { page, limit, query, subAccountId } = payload;
      const response$ = of<IResponse<IPagination<IHistory>>>(
        await topUpHistory(subAccountId, page, limit, query ?? '')
      );
      response$.subscribe((history) => {
        commit('UPDATE_TITLE', TOP_UP_HISTORY_TITLE);
        const { page, limit, pages, total, docs } = history.data.paginateObj;
        commit('UPDATE_GRAPH', history.data.others?.graph);
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IHistory>, RootState> = {
  getHistoryTitles: (state) => state.title,
  getAllHistories: (state) => state.list,
  getPagination: (state) => state.paginate,
  getHistoryGraph: (state) => state.graph,
};

export const history: Module<BaseState<IHistory>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
