import {
  BaseState,
  IPagination,
  IResponse,
  IScheduledEvent,
  PayloadState,
} from '@/types/types';
import { of } from 'rxjs';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { RootState, IPaginate } from '../../types/types';
import {
  deleteEvent,
  details,
  event,
  scheduleEvent,
  updateEvent,
} from '../../services/events.service';
import { isNetworkError } from '@/utils/helpers';
import moment from 'moment';
import { map } from 'rxjs/operators';
import router from '../../router/index';

const state: BaseState<IScheduledEvent> = {
  list: [],
  paginatedList: [],
  details: {
    name: '',
    state: '',
    executionTimestamp: '',
    selectedGroupsOrContacts: [],
    selectedOption: '',
    payload: null,
  },
  paginate: {
    total: 0,
    page: 1,
    pages: 1,
    limit: 30,
  },
};

const mutations: MutationTree<BaseState<IScheduledEvent>> = {
  UPDATE_PAGINATED_LIST(state, payload: Array<IScheduledEvent>) {
    state.paginatedList = payload;
  },
  UPDATE_LIST(state, payload: Array<IScheduledEvent>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload: IScheduledEvent) {
    state.details = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage' | 'pages'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
      pages: payload.pages,
    };
  },
};

const actions: ActionTree<BaseState<IScheduledEvent>, RootState> = {
  async list({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { query } = payload;
      const response$ = of<IResponse<Array<IScheduledEvent>>>(
        await event(query)
      );
      response$.subscribe((docs: IResponse<Array<IScheduledEvent>>) => {
        commit(
          'UPDATE_LIST',
          docs.data?.map((doc) => {
            return {
              ...doc,
              color: ['SCHEDULED', 'STARTED'].includes(doc.state)
                ? 'primary'
                : 'rgb(1, 193, 76)',
              start: moment(doc.executionTimestamp).format('YYYY-MM-DD hh:mm'),
            };
          })
        );
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async paginatedList({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { query } = payload;
      const response$ = of<IResponse<IPagination<IScheduledEvent>>>(
        await event(query)
      );
      response$.subscribe((data: IResponse<IPagination<IScheduledEvent>>) => {
        const { page, limit, pages, total, docs } = data.data.paginateObj;
        commit(
          'UPDATE_PAGINATED_LIST',
          docs?.map((doc) => {
            return {
              ...doc,
              color: ['SCHEDULED', 'STARTED'].includes(doc.state)
                ? 'primary'
                : 'rgb(1, 193, 76)',
              start: moment(doc.executionTimestamp).format('YYYY-MM-DD hh:mm'),
            };
          })
        );
        commit('UPDATE_PAGINATION', {
          page,
          pages,
          total,
          itemsPerPage: limit,
        });
        dispatch('isPageLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      dispatch('isDialogLoading', true, { root: true });
      const { id } = payload;
      const response$ = of<IResponse<IScheduledEvent>>(await details(id));
      response$.subscribe((docs: IResponse<IScheduledEvent>) => {
        commit('UPDATE_DETAILS', docs.data);
        dispatch('isPageLoading', false, { root: true });
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async schedule({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IScheduledEvent>>(
        await scheduleEvent(payload)
      ).pipe(map((data) => data.data));
      response$.subscribe(() => {
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', `SMS Event scheduled successfully`, {
          root: true,
        });
        dispatch('resetFormValues', true, { root: true });
        dispatch('loadEventList', {
          executionTimestamp: payload?.executionTimestamp,
          isPaginated: false,
        });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async updateEvent({ dispatch }, payload) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id, body } = payload;
      const response$ = of<IResponse<IScheduledEvent>>(
        await updateEvent(id, body)
      ).pipe(map((data) => data.data));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', `SMS Event updated successfully`, {
          root: true,
        });
        dispatch('loadEventList', {
          executionTimestamp: payload?.executionTimestamp,
          isPaginated: false,
        });
        dispatch('details', {
          id,
        });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async delete(
    { dispatch },
    payload: { loading: PayloadState; id: number; view: string; date: string }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IScheduledEvent>>(
        await deleteEvent(payload.id)
      ).pipe(map((data) => data.data));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('snackBarVisibility', true, { root: true });
        dispatch('snackBarMessage', `SMS Event deleted successfully`, {
          root: true,
        });
        dispatch('updateDialog', payload.loading, { root: true });
        if (payload?.view && payload?.date) {
          dispatch('loadEventList', {
            executionTimestamp: payload?.date,
            isPaginated: false,
          });
          return;
        }
        router.replace({ name: 'account.events.list' });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async loadEventList(
    { dispatch },
    payload: { executionTimestamp: string; isPaginated: boolean }
  ): Promise<void> {
    setTimeout(() => {
      dispatch('list', {
        query: `?activeDate=${moment(payload?.executionTimestamp).format(
          'YYYY-MM-DD'
        )}&isPaginated=${payload.isPaginated ?? false}`,
      });
    }, 1000);
  },
};

const getters: GetterTree<BaseState<IScheduledEvent>, RootState> = {
  getAllEvents: (state) => state.list,
  getEventDetails: (state) => state.details,
  getAllPaginatedEvents: (state) => state.paginatedList,
  getPagination: (state) => state.paginate,
};

export const events: Module<BaseState<IScheduledEvent>, RootState> = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
