/* eslint-disable @typescript-eslint/no-inferrable-types */

import { Component, Vue, Watch } from 'vue-property-decorator';
import { Getter } from 'vuex-class';

@Component({
  filters: {},
})
export default class WidgetMixins extends Vue {
  @Getter('showSnackBar') showSnackBar!: boolean;
  @Getter('getIsLoading') isLoading!: boolean;
  @Getter('getIsPageLoading') pageLoading!: boolean;
  @Getter('getSnackBarMessage') message!: string;
  @Getter('getInternet') internetState!: boolean;
  @Getter('getSnackBarStatus') snackBarStatus!: string;
  @Getter('getIsDialogLoading') isDialogLoading!: boolean;
  @Getter('getIsBalanceLoading') isBalanceLoading!: boolean;
  @Getter('getInProgress') inProgress!: boolean;
  @Getter('getIsDownloading') isDownloading!: boolean;
  @Getter('getTotalBulkRecords') totalBulkRecords!: number;

  @Watch('showSnackBar')
  onSnackBarChanged(): void {
    setTimeout(async () => {
      await this.$store.dispatch('snackBarVisibility', false, {
        root: true,
      });
    }, this.$store.state.timeout);
  }

  get timeout(): number {
    return this.$store.state.timeout;
  }

  get currentPage(): number {
    return this.$store.getters['getCurrentPage'];
  }

  set currentPage(currentPage: number) {
    this.$store.dispatch('currentPage', { currentPage }, { root: true }).then();
  }

  async closeSnackBar(value: boolean): Promise<void> {
    await this.$store.dispatch('snackBarVisibility', value, { root: true });
  }

  async refresh(link: string): Promise<void> {
    await this.$store.dispatch('isLoading', true, { root: true });
    await this.$store.dispatch(link);
  }
}
