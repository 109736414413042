import {
  BaseState,
  IResponse,
  IPaginate,
  IPagination,
  IGroup,
} from '@/types/types';
import { RootState, IContact } from '../../types/types';
import { MutationTree, ActionTree, GetterTree, Module } from 'vuex';
import { isNetworkError } from '@/utils/helpers';
import { of } from 'rxjs';
import {
  addContactsToGroup,
  addGroup,
  details,
  groups,
  removeGroup,
  searchGroups,
  uploadContactToGroup,
} from '@/services/groups.service';
import { updateGroup } from '../../services/groups.service';

const namespaced = true;
export type Paginate = {
  paginate: {
    total: number;
    page: number;
    limit: number;
  };
};

const state: BaseState<IGroup> = {
  list: [],
  details: {
    id: 0,
    name: '',
    description: '',
  },
  paginate: {
    total: 0,
    page: 1,
    limit: 30,
  },
  reset: {
    name: '',
  },
  searchResults: [],
};

const mutations: MutationTree<BaseState<IGroup>> = {
  UPDATE_LIST(state, payload: ReadonlyArray<IGroup>) {
    state.list = payload;
  },
  UPDATE_DETAILS(state, payload: IGroup) {
    state.details = payload;
  },
  UPDATE_SEARCH_RESULTS(state, payload: ReadonlyArray<IGroup>) {
    state.searchResults = payload;
  },
  UPDATE_PAGINATION(
    state,
    payload: Pick<IPaginate, 'total' | 'page' | 'itemsPerPage'>
  ) {
    state.paginate = {
      page: payload.page,
      total: payload.total,
      limit: payload.itemsPerPage,
    };
  },
};

const actions: ActionTree<BaseState<IGroup>, RootState> = {
  async rawList({ dispatch, commit }) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const response$ = of<IResponse<IGroup>>(await groups(false, 1, 12));
      response$.subscribe((contacts) => {
        commit('UPDATE_LIST', contacts.data);
        dispatch('isPageLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async list({ commit, dispatch }, payload) {
    try {
      dispatch('isPageLoading', true, { root: true });
      const { page, limit } = payload;
      const response$ = of<IResponse<IPagination<IGroup>>>(
        await groups(true, page, limit)
      );
      response$.subscribe((groups) => {
        const { page, limit, total, docs } = groups.data.paginateObj;
        commit('UPDATE_LIST', docs);
        commit('UPDATE_PAGINATION', { page, total, itemsPerPage: limit });
        dispatch('isPageLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isPageLoading', false, { root: true });
    }
  },
  async details({ commit, dispatch }, id) {
    try {
      dispatch('isDialogLoading', true, { root: true });
      const response$ = of<IResponse<IGroup>>(await details(id));
      response$.subscribe((contacts) => {
        commit('UPDATE_DETAILS', contacts.data);
        dispatch('isDialogLoading', false, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isDialogLoading', false, { root: true });
    }
  },
  async create({ state, dispatch }, payload: IGroup) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IGroup>>(await addGroup(payload));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Group added successfully`, {
          root: true,
        });
        dispatch('updateDialog', { idx: 'add', state: false }, { root: true });
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async update({ state, dispatch }, payload: IGroup) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id, ...rest } = payload;
      const response$ = of<IResponse<IGroup>>(
        await updateGroup(Number(id), rest)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Group updated successfully`, {
          root: true,
        });
        dispatch('updateDialog', { idx: 'edit', state: false }, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async delete({ dispatch, state }, id) {
    try {
      dispatch('isLoading', true, { root: true });
      const response$ = of<IResponse<IGroup>>(await removeGroup(id));
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Group deleted successfully`, {
          root: true,
        });

        dispatch(
          'updateDialog',
          { idx: 'delete', state: false },
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async bulkUpload({ dispatch }, payload: { id: number; file: FormData }) {
    try {
      dispatch('isLoading', true, { root: true });
      const { id, ...rest } = payload;
      const response$ = of<IResponse<IGroup>>(
        await uploadContactToGroup(id, rest.file)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: 1,
          limit: 30,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contacts successfully upload to group`, {
          root: true,
        });
        dispatch(
          'updateDialog',
          { idx: 'upload', state: false },
          { root: true }
        );
        dispatch('resetFormValues', true, { root: true });
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async assignContactsToGroup(
    { dispatch, state },
    payload: { groupId: number; contacts: IContact[] }
  ) {
    try {
      dispatch('isLoading', true, { root: true });
      const { groupId, ...rest } = payload;
      const response$ = of<IResponse<IGroup>>(
        await addContactsToGroup(groupId, rest)
      );
      response$.subscribe(() => {
        dispatch('isLoading', false, { root: true });
        dispatch('list', {
          page: (state.paginate as IPaginate).page,
          limit: (state.paginate as IPaginate & { limit: number }).limit,
        });
        dispatch('snackBarVisibility', true, {
          root: true,
        });
        dispatch('snackBarMessage', `Contacts assigned to group successfully`, {
          root: true,
        });
        dispatch(
          'updateDialog',
          { idx: 'contacts', state: false },
          { root: true }
        );
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
  async searchGroups({ dispatch, commit }, payload: { query: string }) {
    try {
      dispatch('isLoading', true, { root: true });
      const { query } = payload;
      const response$ = of<IResponse<Array<IGroup>>>(await searchGroups(query));
      response$.subscribe((results) => {
        dispatch('isLoading', false, { root: true });
        commit('UPDATE_LIST', results.data);
      });
    } catch (e) {
      if (isNetworkError(e)) {
        dispatch('snackBarMessage', e?.message, {
          root: true,
        });
      } else {
        dispatch('snackBarMessage', e.response.data?.message, {
          root: true,
        });
      }
      dispatch('snackBarVisibility', true, { root: true });
      dispatch('isLoading', false, { root: true });
    }
  },
};

const getters: GetterTree<BaseState<IGroup>, RootState> = {
  getPagination: (state) => state.paginate,
  getGroupDetails: (state) => state.details,
  getAllGroups: (state) => state.list,
  getResetFormValues: (state) => state.reset,
  getSearchResults: (state) => state.searchResults,
};

export const group: Module<BaseState<IGroup>, RootState> = {
  namespaced,
  state,
  mutations,
  actions,
  getters,
};
