import { IPayments } from '../types/types';
import { Api } from './api';

export const listPayments = <T>(
  page: number,
  limit: number,
  query?: string
): Promise<T> => {
  return Api().get(
    `/payments?page=${page}&limit=${limit}${query ? query : ''}`
  );
};
export const initiatePayment = <T>(
  payload: Pick<IPayments, 'amount'> & { email: string }
): Promise<T> => {
  return Api().post(`/payments/initiate`, payload);
};

export const verifyPayment = <T>(transactionId: string): Promise<T> => {
  return Api().get(`/payments/verify?transactionId=${transactionId}`);
};
